import "./App.css";
import PublicLayout from "./component/Layout/publicLayout";
import { BrowserRouter as Router } from "react-router-dom";
import CustomRoute from "./Route.jsx";
import { getVideoList } from "./context/utility.jsx";
import { useContext, useEffect } from "react";
import { Context } from "./context/index.jsx";

function App() {
  const { state, dispatch } = useContext(Context);
  const { videoList } = state;
  useEffect(() => {
    async function fetchData() {
      // console.log("fetch");
      const result = await getVideoList();
      // console.log(result);
      dispatch({
        type: "videoList",
        payload: result,
      });
    }
    fetchData();
  }, []);

  return (
    <>
      <Router>
        <PublicLayout>
          <CustomRoute />
        </PublicLayout>
      </Router>
    </>
  );
}

export default App;
