import { initialState } from ".";

export function reducer(state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return initialState;
    case "videoList":
      return {
        ...state,
        videoList: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
