import { useEffect, useState } from "react";
import axios from "axios";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
}
function isJavaScriptDate(obj) {
  return obj instanceof Date;
}
function convertTimestampForSort(timestamp, symbol = "-", type = "number") {
  let date = new Date();

  if (
    typeof timestamp == "number" ||
    !timestamp ||
    typeof timestamp == "string"
  ) {
    let mm = date?.getMonth() + 1;
    let dd = date?.getDate();
    let yyyy = date?.getFullYear();

    date = yyyy + symbol + mm + symbol + dd;
    return date;
  }
  function firebaseTimestampToDate(firebaseTimestamp) {
    if (!firebaseTimestamp) {
      throw new Error("Invalid Firebase timestamp.");
    }

    // Check for the presence of _seconds or seconds
    const seconds = firebaseTimestamp._seconds || firebaseTimestamp.seconds;
    const nanoseconds =
      firebaseTimestamp._nanoseconds || firebaseTimestamp.nanoseconds;

    if (seconds === undefined || nanoseconds === undefined) {
      if (nanoseconds === undefined) {
        return new Date(seconds * 1000);
      } else {
        return new Date();
      }

      // throw new Error("Invalid Firebase timestamp format.");
    }

    // Convert to milliseconds and create a Date object
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  }

  if (isJavaScriptDate(timestamp)) {
    date = new Date(timestamp);
  } else {
    date = firebaseTimestampToDate(timestamp);
  }

  let mm = date?.getMonth() + 1;
  let dd = date?.getDate();
  let yyyy = date?.getFullYear();
  if (type === "long") {
    const monthNamesFull = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNamesFull[date.getMonth()];
    date = `${dd} ${monthName} ${yyyy}`;
  } else if (type === "short") {
    const monthNamesFull = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNamesFull[date.getMonth()];
    date = `${dd} ${monthName}, ${yyyy}`;
  } else {
    date = `${yyyy}${symbol}${mm}${symbol}${dd}`;
  }
  return date;
}
async function getVideoList() {
  try {
    let count = 0;
    const result = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/getInfo?type=videos`
    );
    const currentList = await Promise.all(
      result.data?.map(async (e) => {
        let videoDuration = e.video_length;
        count++;

        // IF do not have thumbnail , add one
        // if (video_tn === "" || getFileType(video_tn) !== "image") {
        //   const video_tnObj = await compressImageFromUrl(
        //     e.video_cover,
        //     0.5,
        //     1280
        //   );
        //   const userFolder = e.video_cover.split("%2F")[0].split("/o/")[1];
        //   const currentLoginID = e.video_cover.split("%2F")[1];
        //   const newId = e.video_cover.split("%2F")[2];
        //   const storageRefVideoTN = ref(
        //     storage,
        //     `/${userFolder}/${currentLoginID}/${newId}/thumbnail.jpeg`
        //   );
        //   const metadata = {
        //     contentType: "image/jpeg",
        //     uploader: e.video_owner,
        //   };
        //   const uploadTask = uploadBytesResumable(
        //     storageRefVideoTN,
        //     video_tnObj,
        //     metadata
        //   );
        //   uploadTask.on(
        //     "state_changed",
        //     (snapshot) => {
        //       const percent = Math.round(
        //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        //       );
        //     },
        //     (err) => alert(err),
        //     () => {
        //       // download url
        //       getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
        //         console.log("count:", count, " ", url);
        //         try {
        //           video_tn = url;
        //           const docRef = doc(db, "accounts", e.video_owner);
        //           const docSnap = await getDoc(docRef);
        //           let user = docSnap.data();
        //           let currentVideoList = user.videos;
        //           const newVideoList = currentVideoList.map((video) => {
        //             if (video.video_id === e.video_id) {
        //               return { ...video, video_tn: url }; // Replace with new form data
        //             } else {
        //               return video; // Keep the existing video data
        //             }
        //           });
        //           const newInfo = {
        //             videos: newVideoList,
        //           };
        //           await updateDoc(docRef, newInfo);
        //         } catch (err) {
        //           console.error(
        //             `Error getting duration for video ${e.video_id}:`,
        //             err
        //           );
        //         }
        //       });
        //     }
        //   );
        // }
        // IF do not have thumbnail , add one
        // const isSmall = await checkFileSize(e.video_preview_tn, e);
        // const isSmall =
        //   new Date(convertTimestampForSort(e.video_release_date)) <
        //   new Date("2024-06-30");
        // const isSmall = false;
        // if (
        //   e.video_preview_tn === "" ||
        //   (!e.video_preview_tn && e.video_preview !== "")
        // ) {
        //   const userFolder = e.video_cover.split("%2F")[0].split("/o/")[1];
        //   const currentLoginID = e.video_cover.split("%2F")[1];
        //   const newId = e.video_cover.split("%2F")[2];
        //   console.log("generating preview: ", e.video_name);
        //   const playListObj = await generateVideosFromURL(
        //     e.video_preview,
        //     ["854x480"],
        //     "preview_tn"
        //   );
        //   const storageRefVideoNewPlayList = ref(
        //     storage,
        //     `/${userFolder}/${currentLoginID}/${newId}/preview_tn.mp4`
        //   );
        //   const url = await uploadToFB(
        //     playListObj[0],
        //     "video/mp4",
        //     e.video_owner,
        //     storageRefVideoNewPlayList,
        //     false
        //   );
        //   const VideoMapping = {
        //     video_preview_tn: url,
        //   };
        //   const videoReff = doc(db, "videos", e.video_id);
        //   console.log(VideoMapping);
        //   await updateDoc(videoReff, VideoMapping);
        // }
        // if (
        //   !videoDuration ||
        //   e.video_hide !==
        //     new Date(convertTimestampForSort(e.video_release_date)) > new Date()
        //   // ||
        //   // video_tn === "" ||
        //   // getFileType(video_tn) !== "image"
        // ) {
        //   console.log("1");
        //   let video_hide = e.video_release
        //     ? new Date(convertTimestampForSort(e.video_release_date)) >
        //       new Date()
        //     : e.video_hide;

        //   const VideoMapping = {
        //     video_length: videoDuration,
        //     video_hide: video_hide,
        //     video_tn: video_tn,
        //   };
        //   const videoReff = doc(db, "videos", e.video_id);
        //   await updateDoc(videoReff, VideoMapping);
        // }
        // if (e.video_id === "3aa006c6-32dc-4e8c-b830-9bc705953856") {
        //   console.log(e.video_release);
        //   console.log(e.video_hide);
        // }

        // IF do not have m3u8 , add one

        // if (
        //   !video_srcAuto &&
        //   e.video_src !== "" &&
        //   e.video_src &&
        //   e.video_id === "111"
        // ) {
        //   // console.log(e.video_src)
        //   //   const res = await verifyVideoURL(e.video_src);

        //   const response = await fetch(e.video_src);
        //   if (!response.ok) throw new Error("Network response was not ok");
        //   const contentType = response.headers.get("Content-Type");

        //   if (!contentType || !contentType.startsWith("video/")) {
        //     console.error("URL does not point to a video file.");
        //     // throw new Error("URL does not point to a video file.");
        //   } else {
        //     // console.log(await response.blob());

        //     const videoBlob = await response.blob();
        //     // Create a File object from the Blob
        //     const lastModified = new Date().getTime(); // Use current time as last modified time, or any other logic
        //     const file = new File([videoBlob], "complete.mp4", {
        //       type: videoBlob.type, // Use the MIME type from the blob, or you can define it manually if needed
        //       lastModified: lastModified,
        //     });
        //     const userFolder = e.video_cover.split("%2F")[0].split("/o/")[1];
        //     const currentLoginID = e.video_cover.split("%2F")[1];
        //     const newId = e.video_id;

        //     const fileSize = bytesToGB(file.szie);
        //     if (fileSize < 2) {
        //       const playListObj = await generateVideosM3U8(
        //         file,
        //         ["1280x720", "640x360"],
        //         "complete",
        //         false,
        //         false,
        //         userFolder,
        //         currentLoginID,
        //         newId,
        //         e.video_owner
        //       );
        //       const name = "completeAuto.m3u8";

        //       const storageRefVideoNewPlayList = ref(
        //         storage,
        //         `/${userFolder}/${currentLoginID}/${newId}/${name}`
        //       );
        //       const url = await uploadToFB(
        //         playListObj,
        //         "application/vnd.apple.mpegurl",
        //         e.video_owner,
        //         storageRefVideoNewPlayList,
        //         true
        //       );

        //       const VideoMapping = {
        //         video_srcAuto: url,
        //       };
        //       const videoReff = doc(db, "videos", e.video_id);
        //       await updateDoc(videoReff, VideoMapping);
        //     }
        //   }
        // }
        return {
          ...e,
          id: e.video_id,
          label: e.video_name,
          value: e.video_id,
          video_id: e.video_id,
          video_hide: e.video_release
            ? new Date(e.video_release_date) > new Date()
            : e.video_hide,
          video_tn: e.video_tn || e.video_cover,
          video_length: videoDuration,
          // ...(video_srcAuto !== "" && { video_srcAuto: video_srcAuto }),
          // ...(video_src !== "" && { video_src: video_src }),
        };
      })
    );

    // console.log(currentList);
    return currentList;
  } catch (error) {
    console.error("Error ", error);
    console.log("Error", error);
    return [];
  }
}
async function sendFeedback(regInfo, type) {
  try {
    const response = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/sendFeedback?type=${type}`,
      regInfo
    );
    return "success";
  } catch (error) {
    console.log("error");
    return "error";
  }
}
function formatTimeFromSeconds(totalSeconds) {
  // Check if total seconds are less than 60
  //   if (totalSeconds < 60) {
  //     return `${Math.floor(totalSeconds)}`; // Just return seconds with 's' suffix if less than a minute
  //   }

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  // Build time string
  const hoursStr = hours > 0 ? `${hours}:` : "";
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

  // Concatenate time parts, skipping hours if it's 0
  return `${hoursStr}${minutesStr}:${secondsStr}`;
}

export {
  useWindowSize,
  getVideoList,
  sendFeedback,
  convertTimestampForSort,
  formatTimeFromSeconds,
};
