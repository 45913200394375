import React, { useEffect, useState, useContext } from "react";
import { Layout } from "antd";
import "./publicLayout.css";
import CSHeader from "../Header/header";
import CSBottom from "../Footer/bottom";
import { useLocation } from "react-router-dom";
// import { Context } from "../../context/index";

export default function PublicLayout({ children }) {
  const { Content } = Layout;
  const location = useLocation();
  //   const { state, dispatch } = useContext(Context);
  //   const { isOpen, isOpenMenu } = state;

  // Check if the current URL starts with "/play"
  const isPlayPage = location.pathname.endsWith("/");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  return (
    <>
      <Layout className="Layout">
        <CSHeader />
        <Content
          style={{
            margin: "0 auto",
            height: "auto",
            minHeight: "100%",
            background: "#fff",
            maxWidth: isPlayPage ? "100%" : "1280px",
            width: "100%",
            //   background: "#000",
            // paddingBottom: "100px",
            // zIndex: 1,
          }}
        >
          {children}
        </Content>
        <CSBottom style={{ zIndex: 9999 }} />{" "}
      </Layout>
    </>
  );
}
