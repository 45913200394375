import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.js";
import "./index.css";
import { Provider as ContextProvider } from "./context/index.jsx";
import { ConfigProvider } from "antd";

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <ContextProvider>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </ContextProvider>
  </StrictMode>
);
