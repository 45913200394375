import { Link, useLocation } from "react-router-dom";
import "./header.css";
import isMobileDevice from "../../context/getUserClient";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Context } from "../../context/index";
import { RiShieldCheckLine, RiVideoUploadFill } from "react-icons/ri";
import { LogoutOutlined, VideoCameraOutlined } from "@ant-design/icons";

import { Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

let mobile = isMobileDevice();

export default function NavbarM({ isLogin, setIsLogin }) {
  const { state, dispatch } = useContext(Context);
  const {
    language,
    currentLoginAccount,
    currentLoginID,
    currentLoginPermission,
  } = state;
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const localLoginID = JSON.parse(localStorage.getItem("loginID"));
  function logOut() {
    setIsLogin(false);
    localStorage.clear();
    localStorage.setItem("confirmOver18", true);
    dispatch({
      type: "RESET_STATE",
    });
    dispatch({
      type: "over18",
      payload: true,
    });

    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        margin: "0 auto",
        display: "flex",
        width: "100%",
        // maxWidth: "1280px",
        justifyContent: "center",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", userSelect: "none" }}
      >
        <Link onClick={() => onClose()} to="/" className="no-hover">
          <img
            src="./ui/logo.png"
            alt="logo"
            style={{ width: "50px", height: "50px" }}
          />
        </Link>
      </div>

      <MenuOutlined
        onClick={() => setOpen(true)}
        style={{
          cursor: "pointer",
          position: "absolute",
          right: 20,
          top: 15,
          fontSize: "30px",
          color: "#fff",
        }}
      />
      {open && (
        <Drawer
          title={
            <>
              {" "}
              {/* {isLogin ? (
                <div id="Login_group" style={{ justifyContent: "center" }}>
                  <div className="user_btn">
                    <img
                      src="./ui/user_outline.png"
                      style={{ height: "20px", width: "auto" }}
                    />
                    <div> {currentLoginAccount} </div>
                  </div>
                  {(currentLoginPermission === "free" ||
                    currentLoginPermission === "guest") && (
                    <div className="JOIN_NOW_BN">
                      <Link onClick={()=>onClose()}
                        to="/payment"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          width: "100%",
                        }}
                        onClick={() => onClose()}
                      >
                        <span>
                          {language === "EN" ? "JOIN NOW" : "加入会员"}
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
              ) : (
                <div id="Login_group" style={{ justifyContent: "center" }}>
                  <div
                    className="LOGIN_BN"
                    onClick={() => {
                      // setLogin(true);
                      dispatch({
                        type: "isOpen",
                        payload: true,
                      });
                      onClose();
                    }}
                  >
                    <span>{language === "EN" ? "LOGIN" : "登陆"}</span>
                  </div>
                  <div className="JOIN_NOW_BN">
                    <Link onClick={()=>onClose()}
                      to="/register"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        width: "100%",
                      }}
                      onClick={() => onClose()}
                    >
                      <span>{language === "EN" ? "JOIN NOW" : "加入会员"}</span>
                    </Link>
                  </div>
                </div>
              )} */}
            </>
          }
          placement={"right"}
          closable={false}
          onClose={onClose}
          open={open}
          key={"right"}
          style={{ background: "#1a1a1a" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: "0px 5%",
              justifyContent: "left",
              alignItems: "center",
              gap: "5%",
              background: "#1a1a1a",
              fontSize: "22px",
            }}
          >
            <div>
              <Link
                onClick={() => onClose()}
                to={"/"}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="video_bn_M">
                  <div id="VIDEOS">
                    <span>首页</span>
                  </div>
                </div>
              </Link>
            </div>
            <div>
              <Link
                onClick={() => onClose()}
                to={"https://lan-gui-fang.com/"}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="video_bn_M">
                  <div id="VIDEOS">
                    <span>兰桂坊</span>
                  </div>
                </div>
              </Link>
            </div>

            <Link
              onClick={() => onClose()}
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn_M">
                <div id="VIDEOS">
                  <span>TG群</span>
                </div>
              </div>
            </Link>
            <Link
              onClick={() => onClose()}
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn_M">
                <div id="VIDEOS">
                  <span>电影线上看</span>
                </div>
              </div>
            </Link>
            <Link
              onClick={() => onClose()}
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn_M">
                <div id="VIDEOS">
                  <span>绅士游戏</span>
                </div>
              </div>
            </Link>
            <Link
              onClick={() => onClose()}
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn_M">
                <div id="VIDEOS">
                  <span>H漫画</span>
                </div>
              </div>
            </Link>
            <Link
              onClick={() => onClose()}
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn_M">
                <div id="VIDEOS">
                  <span>更多</span>
                </div>
              </div>
            </Link>
            {isLogin && (
              <div
              // style={{ background: "#fff", width: "100%", height: "400px" }}
              >
                <Link
                  to={"/myAccount"}
                  className="user_menu_item"
                  onClick={() => onClose()}
                >
                  <div id="Security_profile">
                    {" "}
                    <span className="menu-X">X</span>My Account
                  </div>
                </Link>

                <Link
                  className="user_menu_item"
                  to={"/myVideo"}
                  onClick={() => onClose()}
                >
                  <VideoCameraOutlined />
                  <div id="Security_profile">My Videos</div>
                </Link>

                {(currentLoginPermission === "admin" ||
                  currentLoginPermission === "official") && (
                  <Link
                    className="user_menu_item"
                    to={"/upload"}
                    onClick={() => onClose()}
                  >
                    <RiVideoUploadFill />
                    <div id="Security_profile">Upload</div>
                  </Link>
                )}

                <div className="user_menu_item" onClick={() => logOut()}>
                  <LogoutOutlined />
                  <div>Log Out</div>
                </div>
              </div>
            )}
          </div>
        </Drawer>
      )}
    </div>
  );
}
