import { lazy } from "react";
import RequireAuth from "./RequireAuth.jsx";

// const MyVideoAdmin = lazy(() => import("./pages/Admin/myVideoAdmin"));
export const Path = [
  {
    path: "/",
    component: lazy(() => import("./pages/Home/main.js")),
  },
  {
    path: "/link",
    component: lazy(() => import("./pages/Coop/coop.js")),
  },
  {
    path: "/text",
    component: lazy(() => import("./pages/Terms/text.js")),
  },
  {
    path: "/submit",
    component: lazy(() => import("./pages/User/submit.js")),
  },
  {
    path: "/video",
    component: lazy(() => import("./pages/Video/video.js")),
  },
  {
    path: "/DwI9spy3bYC533sjXsVo",
    component: lazy(() => import("./pages/Admin/admin.js")),
  },
  // {
  //   path: "/myVideoAdmin",
  //   component: () => (
  //     <RequireAuth
  //       Component={MyVideoAdmin}
  //       permissionKey="permission"
  //       admin={true}
  //     />
  //   ),
  // },
  // {
  //   path: "/login",
  //   component: lazy(() => import("./pages/Login/loginPage")),
  // },
  {
    path: "*",
    component: lazy(() => import("./pages/Error/errormsg")),
  },
];
